I18n.translations || (I18n.translations = {});
I18n.translations["de"] = I18n.extend((I18n.translations["de"] || {}), {
  "js": {
    'shared': {
      "flash": {
        "error": "Fehler",
        "success": "Erfolgreich",
        "warning": "Warnung"
      }
    },
    'raw_data': {
      'status': {
        'system_ignored': 'Vom System ignoriert',
        'user_ignored': 'Vom Benutzer ignoriert',
        'processable_not_connected': 'Verbinden Sie Stripe zu verarbeiten',
        'processable': 'Versandfertig in ',
        'error': 'Error'
      },
      'days': ' Tagen'
    },
    'errors': {
      'permission': "Sie haben keine erforderlichen Berechtigungen, um diese Aktion auszuführen",
      'folder_access_permission': "Sie haben keine Berechtigung zum Hochladen in diesen Ordner"
    }
  }
});
