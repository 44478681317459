I18n.locale = document.documentElement.lang;
I18n.defaultLocale = 'de';


import Vue from 'vue/dist/vue.esm'
import VueResource from 'vue-resource';

Vue.use(VueResource);

document.addEventListener('DOMContentLoaded', () => {
    let rootElement = document.querySelector('#locale');
    if (!rootElement) {return}
    Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    new Vue({
        el: rootElement,
        data: {},
        created: function () {
            let vm = this;
        },
        methods: {
            setLocale(locale) {
                if (locale === I18n.locale) { return }
                window.location.search += `&locale=${locale}`
            },
            localeClass(locale) {
                let _class = 'footer-nav__itm-link'
                if (locale === I18n.locale) {
                    _class = 'footer-nav__itm-link-active'
                }
                return _class
            }
        }
    })
});