document.addEventListener('DOMContentLoaded', () => {
  let messages = Array.from(document.querySelectorAll('[data-flash-message]'));
  messages.forEach(function (element) {
    let type = element.getAttribute('data-flash-message');
    let message = element.innerText;
    toastr.options = {
      "progressBar": true,
      "timeOut": "8000"
    };
    switch (type) {
      case 'success':
        toastr.success(message, I18n.t('js.shared.flash.success'));
        break;
      case 'warning':
        toastr.warning(message, I18n.t('js.shared.flash.warning'));
        break;
      case 'alert':
      case 'error':
        toastr.error(message, I18n.t('js.shared.flash.error'));
        break;
      default:
        toastr.info(message);
    }
  })
});
